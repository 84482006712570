<template>
  <div>
    <el-date-picker
      :value-format="valueFormat"
      v-model="timeSection"
      :type="type"
      range-separator="-"
      :default-time="['00:00:00', '23:59:59']"
      @change="changeTime"
      :clearable="clearable"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      unlink-panels
      :picker-options="pickerOptions"
    >
    </el-date-picker>
    <i class="el-icon-date el-icon-date-custom"></i>
  </div>
</template>

<script>
import { getZeroOrLastDateTime } from "@/common/js/utils";
import { dateFormat } from "@/common/js/public.js";
export default {
  props: {
    valueFormat: {
      type: String,
      default: "yyyy-MM-dd HH:mm:ss",
    },
    type: {
      type: String,
      default: "datetimerange",
    },
    // 是否显示清除按钮
    clearable: {
      type: Boolean,
      default: true,
    },
    defalutDate: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 配置参数后可选择默认时间段  plateNumber-车牌  operatorName-操作人
    defaultRange: [],
  },
  watch: {
    valueFormat: function (format) {
      this.resetTime();
    },
    defalutDate: {
      handler(val, oldVal) {
        if (val && val.length) {
          this.resetTime();
        }
      },
      // 这里是关键，代表递归监听 items 的变化
      deep: true,
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "昨日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近30天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近90天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近180天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      defalutDate1: [],
      timeSection: [],
      timeArray: [
        getZeroOrLastDateTime(this.valueFormat, 0),
        getZeroOrLastDateTime(this.valueFormat, 0, false),
      ], // 当天0点 - 23：59
      dayArray: [
        getZeroOrLastDateTime(this.valueFormat, -1),
        getZeroOrLastDateTime(this.valueFormat, 0),
      ], // 昨天  - 今天
      monthArray: [
        getZeroOrLastDateTime(this.valueFormat, -365),
        getZeroOrLastDateTime(this.valueFormat, -30),
      ], // 12月前 - 当月

      dayArray30: [
        getZeroOrLastDateTime(this.valueFormat, -30),
        getZeroOrLastDateTime(this.valueFormat, 0),
      ], // 30天前  - 今天
      dayArray90: [
        getZeroOrLastDateTime(this.valueFormat, -90),
        getZeroOrLastDateTime(this.valueFormat, 0),
      ], // 90天前  - 今天
      dayArray180: [
        getZeroOrLastDateTime(this.valueFormat, -180),
        getZeroOrLastDateTime(this.valueFormat, 0),
      ], // 180天前  - 今天
    };
  },
  methods: {
    dataTimeRest(num) {
      // let nowDate = new Date();
      let yesTime = new Date().getTime() + num * 24 * 60 * 60 * 1000;
      let yesDate = new Date(yesTime);
      let year = yesDate.getFullYear();
      let month =
        yesDate.getMonth() + 1 < 10 ? "0" + (yesDate.getMonth() + 1) : yesDate.getMonth() + 1;
      let day = yesDate.getDate() < 10 ? "0" + yesDate.getDate() : yesDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    // 重置成初始值
    resetTime() {
      console.log(this.defalutDate, "this.defalutDate");
      if (this.defalutDate.length > 0) {
        // 如果有默认时间显示默认时间
        this.timeSection = [...this.defalutDate];
      } else {
        if (this.valueFormat == "yyyy-MM-dd") {
          this.timeSection = this.dayArray;
        } else if (this.valueFormat == "yyyy-MM") {
          this.timeSection = this.monthArray;
        } else {
          this.timeSection = [
            this.dataTimeRest(0) + " 00:00:00",
            this.dataTimeRest(0) + " 23:59:59",
          ];
        }
      }
      this.$emit("timeChange", this.timeSection);
    },
    // 点击确定改变时间，告诉父组件
    changeTime(val) {
      if (val) {
        this.$emit("timeChange", val);
      } else {
        this.$emit("timeChange", ["", ""]);
      }
    },
  },
  mounted() {
    this.resetTime();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.el-icon-date-custom{
  position: relative
  left: -26px;
}
</style>
